.full-width {
  width: 100%;
}

.loader_wrapper {
  &.mainLoader {
    background: rgba(0, 0, 0, 0.1);
    z-index: 99999;
  }

  &.mainLoader {
    background: rgba(0, 0, 0, 0.1);
    z-index: 99999;
  }

  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: auto;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
