.custom-quill .ql-container {
    background: white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 100%;
    border: 1px solid #FFA7B7;
    overflow: hidden; 
    font-size: 14px;
  }
  
  .custom-quill .ql-editor {
    background: white;
    font-size: 14px;
    padding: 10px;
    min-height: 150px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: auto;
  }

  .custom-quill .ql-toolbar {
    background-color: white;
    border: 1px solid #FFA7B7;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }  